import { LocationStrategy, PathLocationStrategy } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppComponent } from './app.component';
import { PagelayoutComponent } from './layout/pagelayout/pagelayout.component';
import {MappingInvoiceComponent} from './mapping-invoice/mapping-invoice.component';
import {BookinghistoryComponent} from './bookinghistory/bookinghistory.component';
import {Error404Component} from './error404/error404.component';
import {DashboardComponent} from './dashboard/dashboard.component';
import {InvoicesComponent} from './invoices/invoices.component';
const routes: Routes = [

  // {
  //   path: '', 
  //   component: LoginComponent
  // },
  {
    path: '', component: PagelayoutComponent,children: [
     { path: '', component: MappingInvoiceComponent, pathMatch: 'full' }, 
     { path: 'billupload/:rowid', component: MappingInvoiceComponent, pathMatch: 'full' }, 
    //  { path: 'bookinghistory', component: BookinghistoryComponent, pathMatch: 'full' }, 
     { path: 'billupload', component: MappingInvoiceComponent, pathMatch: 'full' },
    //  { path: 'invoices', component: InvoicesComponent, pathMatch: 'full' },
    //  { path: 'error404', component: Error404Component, pathMatch: 'full' },
    //  { path: 'changepassword', component: ChangepasswordComponent , pathMatch: 'full',canActivate: [AuthGuard] }, 
    //  { path: 'mybooking', component: MybookingComponent , pathMatch: 'full',canActivate: [AuthGuard] }, 
    ]
  }, 


  

];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
