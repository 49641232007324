<ng2-toasty></ng2-toasty>
<div class="jumbotron">
    <main class="content-wrapper" id="closed">
        <div class="container-fluid">
            <div class="main_content_container">
                <br> 
                <br> <br>
                <section class="shop-checkouts spacegiven">
                    <div class="container">
                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <h4>Invoice Details</h4>
                            <hr />
                            <div class="row" style="display: grid;">
                                <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                                    <label>Invoice Upload ( jpg / png / pdf )<span class="star"> *</span></label><br>

                                    <p-fileUpload #fubauto mode="basic" name="file" url="{{URL1}}"
                                        accept="application/pdf,image/*"
                                        (onUpload)="onBasicUploadAutoPdf($event,'data','tariff')"
                                        (onSelect)="onBasicUploadAutoPdfCheck($event,'data')" [auto]="true"
                                        chooseLabel="Browse"></p-fileUpload> <br />
                                    <label style="max-width:250px;display: inline;">{{FileName}}</label>
                                </div>
                                <br />
                                <div class="row" style="padding-left: 45px;">
                                    <div class="row" style="width: 50%;display: grid;">
                                        <div class="row">
                                            <label style="width: 20%;" for="" class="f-b">Hotel Name</label>
                                            <label style="width: 70%;word-break: break-all ;">: {{_InvoiceDetails[0]?.Hotel_Name}}</label>
                                        </div>
                                        <!-- <br> -->
                                        <div class="row">
                                            <label style="width: 20%;" for="" class="f-b">Hotel GStIN</label>
                                            <label style="width: 70%;">: {{_InvoiceDetails[0]?.Hotel_GSTIN}}</label>

                                        </div>
                                        <!-- <br> -->
                                        <div class="row">
                                            <label style="width: 20%;" for="" class="f-b">Bill No</label>
                                            <label style="width: 70%;">: {{_InvoiceDetails[0]?.BillNo}}</label>

                                        </div>
                                        <!-- <br> -->
                                        <div class="row">
                                            <label style="width: 20%;" for="" class="f-b">Guest Name</label>
                                            <label style="width: 70%;word-break: break-all ;">: {{_InvoiceDetails[0]?.GuestName}}</label>

                                        </div>
                                        <!-- <br> -->
                                        <div class="row">
                                            <label style="width: 20%;" for="" class="f-b">HB GST</label>
                                            <label style="width: 70%;">: {{_InvoiceDetails[0]?.HB_GST}}</label>

                                        </div>
                                        <!-- <br> -->
                                        <div class="row">
                                            <label style="width: 20%;" for="" class="f-b">Check-In Date</label>
                                            <label style="width: 70%;">: {{_InvoiceDetails[0]?.CheckIn_Date}}</label>

                                        </div>
                                        <!-- <br> -->
                                        <div class="row">
                                            <label style="width: 20%;" for="" class="f-b">Check-Out Date</label>
                                            <label style="width: 70%;">: {{_InvoiceDetails[0]?.CheckOut_Date}}</label>

                                        </div>
                                        <!-- <br> -->
                                        <div class="row">
                                            <label style="width: 20%;" for="" class="f-b">Stay Tariff</label>
                                            <label style="width: 70%;">: {{_InvoiceDetails[0]?.Stay_tariff}}</label>

                                        </div>
                                        <!-- <br> -->
                                        <div class="row">
                                            <label style="width: 20%;" for="" class="f-b">Toatl CGST</label>
                                            <label style="width: 70%;">: {{_InvoiceDetails[0]?.Total_CGST}}</label>
                                        </div>
                                    </div>
                                    <div class="row" style="width: 50%;display: grid;">
                                        <div class="row">
                                            <label style="width: 20%;" for="" class="f-b">Toatl SGST</label>
                                            <label style="width: 70%;">: {{_InvoiceDetails[0]?.Total_SGST}}</label>
                                        </div>
                                        <div class="row">
                                            <label style="width: 20%;" for="" class="f-b">Advance</label>
                                            <label style="width: 70%;">: {{_InvoiceDetails[0]?.Advance}}</label>
                                        </div>
                                        <!-- <br> -->
                                        <div class="row">
                                            <label style="width: 20%;" for="" class="f-b">Total After Advance</label>
                                            <label style="width: 70%;">: {{_InvoiceDetails[0]?.Total_after_advance}}</label>

                                        </div>
                                        <!-- <br> -->
                                        <div class="row">
                                            <label style="width: 20%;" for="" class="f-b">Total Amount</label>
                                            <label style="width: 70%;">: {{_InvoiceDetails[0]?.Total_amt}}</label>

                                        </div>
                                        <!-- <br> -->
                                        <div class="row">
                                            <label style="width: 20%;" for="" class="f-b">Tariff Perday</label>
                                            <label style="width: 70%;">: {{_InvoiceDetails[0]?.Tariff_perday}}</label>

                                        </div>
                                        
                                        <!-- <br> -->
                                        <div class="row">
                                            <label style="width: 20%;" for="" class="f-b">Day CGST</label>
                                            <label style="width: 70%;">: {{_InvoiceDetails[0]?.Day_CGST}}</label>

                                        </div>
                                        <!-- <br> -->
                                        <div class="row">
                                            <label style="width: 20%;" for="" class="f-b">Day SGST</label>
                                            <label style="width: 70%;">: {{_InvoiceDetails[0]?.Day_SGST}}</label>

                                        </div>
                                        <!-- <br> -->
                                        <div class="row">
                                            <label style="width: 20%;" for="" class="f-b">Invoice Date</label>
                                            <label style="width: 70%;">: {{_InvoiceDetails[0]?.Invoice_Date}}</label>

                                        </div>
                                        <!-- <br> -->
                                        <div class="row">
                                            <label style="width: 20%;" for="" class="f-b">File Name</label>
                                            <label style="width: 70%;">: {{_InvoiceDetails[0]?.FileName}}</label>

                                        </div>
                                        <!-- <br> -->
                                        <!-- <div class="row">
                                            <label style="width: 20%;" for="" class="f-b">File Link</label>
                                            <label style="width: 70%;word-break: break-all ;">: {{_InvoiceDetails[0]?.FileLink}}</label>
                                        </div> -->
                                    </div>
                                </div>
                                <!-- <p-table #dt [columns]="cols" [value]="_InvoiceDetails" [resizableColumns]="true"
                                    [autoLayout]="true" [responsive]="true" [paginator]="true" [rows]="10"
                                    [showCurrentPageReport]="true" [scrollable]="true" scrollHeight="400px" 
                                    scrollDirection="horizontal"
                                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries">
                                    <ng-template pTemplate="caption">
                                        <div style="text-align: right">
                                            <i class="pi pi-search" style="margin:4px 4px 0 0"></i>
                                            <input type="text" pInputText size="25" placeholder="Global Filter"
                                                (input)="dt.filterGlobal($event.target.value, 'contains')"
                                                style="width:auto">
                                        </div>
                                    </ng-template>
                                    <ng-template pTemplate="header" let-columns>
                                        <tr>
                                            
                                            <th *ngFor="let col of columns" [pSortableColumn]="col.field">
                                                {{col.header}}
                                                <p-sortIcon [field]="col.field"></p-sortIcon>
                                            </th>
                                        </tr>
                                    </ng-template>
                                    <ng-template pTemplate="body" let-rowData let-columns="columns">
                                        <tr>
                                           
                                            <td *ngFor="let col of columns">
                                                <span class="ui-column-title">{{col.header}}</span>
                                                <span *ngIf="col.header!='Ordered Items'"
                                                    style="word-break: break-word;text-align: center;">{{rowData[col.field]}}</span>

                                                <button *ngIf="col.header=='Ordered Items'"
                                                    (click)="FnViewCart(rowData[col.field])" pButton type="button"
                                                    icon="pi pi-eye" class="ui-button-info" title="View"></button>


                                            </td>
                                        </tr>
                                    </ng-template>
                                    <ng-template pTemplate="summary">
                                        <p style="text-align: center;" *ngIf="Orders.length==0">No records
                                            found </p>
                                    </ng-template>
                                </p-table> -->
                            </div>
                        </div>
                    </div>
                </section>
                <!-- <br /> -->
            </div>
        </div>
    </main>
</div>
<!-- <br /><br /> -->
<!-- <br /> -->