	<!-- Main Header Nav -->
    

        <nav class="navbar navbar-expand-lg navbar-dark ftco_navbar bg-dark ftco-navbar-light" id="ftco-navbar" style="background-color: white;color:black;">
          <div class="container">
            
            <a class="navbar-brand" href="#" style="padding-left: 20px;">
              <img class="img-fluid" style="height:85px;" src="https://portalvhds4prl9ymlwxnt8.blob.core.windows.net/img/HBD_logo.png" alt="">
          
          </a>
            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#ftco-nav" aria-controls="ftco-nav" aria-expanded="false" aria-label="Toggle navigation">
              <span class="fa fa-bars"></span> Menu
            </button>
            <div class="collapse navbar-collapse" id="ftco-nav">
             
              <ul class="navbar-nav ml-auto">
                <!-- <li class="nav-item " id="dashboards">
                  <a class="nav-link" (click)="menuclick('dashboard')" [routerLink]="['']" >Dashboard <span class="sr-only">(current)</span></a>
                </li> -->
                <li class="nav-item" id="Invoice">
                  <a class="nav-link" (click)="menuclick('billupload')"  [routerLink]="['/billupload']">Bill Upload</a>
                </li>
                <!-- <li class="nav-item" id="Bookings">
                    <a class="nav-link" (click)="menuclick('bookings')" [routerLink]="['/bookinghistory']">Bookings</a>
                  </li> 
                  <li class="nav-item" id="TotalInvoice">
                    <a class="nav-link" (click)="menuclick('invoices')" [routerLink]="['/invoices']">Invoices</a>
                  </li> -->
                  </ul> 
            </div>
          </div>
        </nav>