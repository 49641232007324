import {Injectable} from '@angular/core'; 
import { HttpClient,HttpParams, HttpHeaders } from '@angular/common/http'; 

@Injectable()
export class Httpclient {

  constructor(private http: HttpClient) {}

  createAuthorizationHeader(headers: Headers) {
    headers.append('x-access-token', localStorage.getItem('_uid'));
  }
   //baseUrl="http://localhost:3000/api/";
   baseUrl="https://testinvoiceextractfront.staysimplyfied.com/api/";
   invoiceUrl="https://testinvoiceextract.staysimplyfied.com/api/"
  
  get(url) {
    let apiRouteUrl= this.baseUrl + url;
    var token = localStorage.getItem("_uid"); 
    if(token==null) {      
      token="";  
    }
    let headers = new HttpHeaders().set('x-access-token', token);
    return this.http.get(apiRouteUrl,{headers});
  }

  post(url, data) {
      
    
    let apiRouteUrl= this.baseUrl + url;
    var token = localStorage.getItem("_uid");
    if(token==null) {      
      token="";  
    }
    let headers = new HttpHeaders().set('x-access-token', token); 
    return this.http.post(apiRouteUrl, data, { headers });
    
  }

  post1(url, data) {
      
    
    let apiRouteUrl= this.baseUrl + url;
    // var token = localStorage.getItem("_uid");
    // if(token==null) {      
    //   token="";  
    // }
    // let headers = new HttpHeaders().set('x-access-token', token); 
    return this.http.post(apiRouteUrl, data);
    
  }
}